<template>
  <div class="search-box">
    <div class="back" @click="$router.go(-1)">
      <svg-icon icon-class="back"></svg-icon>
    </div>
    <van-search
      shape="round"
      background="#ffffff"
      v-model="value"
      placeholder="请输入搜索关键词"
      class="input"
      :left-icon="leftIcon"
      @clear="clear"
      @input="$emit('input', value)"
    ></van-search>
    <div class="search" @click="$emit('search', value)">搜索</div>
  </div>
</template>
<script>
import { Search } from 'vant';

export default {
  props: {
    searValue: {
      type: String,
    },
  },
  data() {
    return {
      value: '',
      leftIcon: require('@/assets/png/Search.png'),
    };
  },
  components: {
    [Search.name]: Search,
  },
  watch: {
    searValue: {
      handler(newName, oldName) {
        this.value = newName;
      },
      immediate: true,
    },
  },
  methods: {
    clear() {
      this.$emit('clear');
    },
  },
};
</script>
<style lang="scss" scoped>
.search-box {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  background-color: #ffffff;

  .back {
    width: 30px;
    cursor: pointer;

    svg {
      font-size: 24px;
    }
  }

  .input {
    width: 100%;
    height: 100%;
    color: #666666;
    background-color: #ffffff;
  }

  /deep/ .van-search__content {
    background: #f2f5f6;
    // border: 1px solid #f6e4fb;
    ::placeholder {
      color: #999999;
    }
  }

  /deep/ .van-field__control {
    color: #666666;
  }

  /deep/ .van-field__left-icon {
    display: flex;
    align-items: center;
    padding-left: 8px;
  }

  .search {
    width: 50px;
    text-align: center;
    font-size: 16px;
    color: #333333;
  }
}
</style>
