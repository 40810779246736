<template>
  <div class="topic_more">
    <Search ref="search" @search="search" @input="input" @clear="clear" />
    <!--    当前选中-->
    <div class="cur_topic">
      <div class="tipic_item" v-for="(i, index) in selectArr" :key="i.id">
        <div class="ellipsis tipc_text">
          <svg-icon icon-class="tip_tag" />
          {{ i.name }}
        </div>
        <div class="tic_close" @click="detlTopic(index)">
          <svg-icon icon-class="close8" />
        </div>
      </div>
    </div>
    <!--    选择-->
    <div class="tip_more">
      <van-checkbox-group v-model="result" :max="5">
        <PullRefresh
          :loading="loading"
          :refreshing="refreshing"
          :finished="finished"
          @onLoad="onLoad"
          @onRefresh="onRefresh"
          :isHigehtMax="true"
          :isNoData="isNoData"
          :error="error"
        >
          <div
            class="post-top"
            v-for="(tag, index) in list"
            :key="index"
            @click="clickCheckbox(tag, index)"
          >
            <div class="post-top-left flex-center-center" :asd="tag.id">
              <div class="post-avatar">
                <ImgDecypt :src="tag.coverImg" />
              </div>
              <div class="post-info">
                <div class="username_box ellipsis">
                  <span>#{{ tag.name }}</span>
                </div>
                <!--显示粉丝-->
                <div class="fan">
                  <div class="fan_text">
                    话题总播放次数: {{ tag.playCount | watchCount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="post-top-right">
              <van-checkbox :name="tag.id" ref="checkboxes">
                <template #icon="props">
                  <svg-icon
                    icon-class="add_actived"
                    v-if="props.checked"
                    class="add_actived"
                  />
                  <svg-icon icon-class="add_notactive" v-else />
                </template>
              </van-checkbox>
            </div>
          </div>
        </PullRefresh>
      </van-checkbox-group>
    </div>
    <div class="btn_box">
      <div class="confirm" @click="confirm">确认</div>
    </div>
  </div>
</template>
<script>
import Search from '@/components/Search';
import { searchTagorUser } from '@/api/community';
import { Checkbox, CheckboxGroup, Cell, CellGroup, Toast } from 'vant';
import ImgDecypt from '@/components/ImgDecypt';
import { getTagList } from '@/api/community';
import PullRefresh from '@/components/PullRefresh';
import { mapGetters } from 'vuex';

export default {
  components: {
    Search,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      result: [],
      checked: '',
      pageNumber: 1,
      pageSize: 20,
      list: [],
      saveList: [],
      selectArr: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      keyWords: '',
    };
  },
  computed: {
    ...mapGetters({
      tags: 'tags',
    }),
  },
  created() {
    this.getList();
    // if (this.tags?.length) {
    //   this.result = this.tags.map(e => e.name);
    // }
  },
  mounted() {
    this.tags.forEach((i) => {
      this.result.push(i.id);
    });
    this.selectArr = this.selectArr.concat(this.tags);
  },
  methods: {
    input(val) {
      if (val.length == 0) {
        this.list = this.saveList;
      }
    },
    clear() {
      this.keyWords = '';
      this.pageNumber = 1;
    },
    search(val) {
      this.keyWords = val;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.pageNumber = 1;
      this.list = [];
      this.getList();

      // let list = this.list.filter(e => val == e.name);
      // if (list.length > 0) {
      //   this.list = list;
      //   return;
      // }
      // Toast("暂无数据")
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    detlTopic(index) {
      this.result.splice(index, 1);
      this.selectArr.splice(index, 1);
    },
    async getList(type) {
      let req = {
        keyWords: [this.keyWords],
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        realm: 'tag',
      };
      try {
        let res = await this.$Api(searchTagorUser, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
            this.saveList = list;
          } else {
            this.list = this.list.concat(list);
            this.saveList = this.saveList.concat(list);
          }
          if (this.pageNumber == 1 && this.list.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },
    confirm() {
      if (this.result.length > 0) {
        // let newArr = []
        // for (let i = 0; i < this.list.length; i++) {
        //   for (let j = 0; j < this.result.length; j++) {
        //     this.result[i]
        //     if (this.list[i].name == this.result[j]) {
        //       newArr.push(this.list[i]);
        //     }
        //   }
        // }
        // console.log(this.result)
        this.$store.commit('commnity/ADD_TAGS', this.selectArr);
        this.$router.go(-1);
      }
    },
    clickCheckbox(tag, index) {
      this.$refs.checkboxes[index].toggle();
      if (this.result.length === 5) {
        Toast('最多只能选择5个标签');
        return;
      }
      let hasTag = false;
      if (this.selectArr.length > 0) {
        this.selectArr.forEach((i, j) => {
          if (i.id == tag.id) {
            this.selectArr.splice(j, 1);
            hasTag = true;
          }
        });
      }
      if (!hasTag) {
        this.selectArr.push(tag);
      }
      // console.log(this.selectArr)
      // this.list.forEach(i => {
      //   this.result.forEach(id => {

      //   })
      // })
    },
    // changeTag(a) {
    //   console.log(a)
    // },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.getList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.getList('refresh');
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-checkbox__icon {
  width: 24px;
  height: 24px;
}

/deep/ .van-checkbox-group {
  height: 100%;
}

/deep/ .van-list {
  padding-bottom: 200px;
}

.topic_more {
  height: 100%;

  .cur_topic {
    display: flex;
    height: 41px;
    align-items: center;
    overflow-x: auto;

    .tipic_item {
      display: inline-block;
      max-width: 300px;
      height: 24px;
      font-size: 12px;
      background: #e8e8e8;
      border-radius: 20.5px;
      position: relative;
      line-height: 24px;
      margin: 0 5px;
      color: #666666;

      .tipc_text {
        padding-left: 12px;
        padding-right: 32px;
      }

      .tic_close {
        position: absolute;
        top: 4px;
        right: 10px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        > svg {
          font-size: 16px;
        }
      }
    }
  }

  .tip_more {
    box-sizing: border-box;
    height: 100%;
    .post-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 12px;
      background: #ffffff;
      margin-bottom: 4px;

      .post-top-left {
        .post-avatar {
          width: 64px;
          height: 64px;
          border-radius: 11px;
          margin-right: 8px;
          overflow: hidden;
        }

        .post-info {
          width: 220px;
          display: flex;
          flex-direction: column;

          .username_box {
            span {
              font-size: 16px;
              letter-spacing: -0.41px;
              color: #333333;
            }
          }

          .fan {
            font-size: 12px;
            padding-top: 13px;

            .fan_text {
              color: #999999;
            }
          }
        }
      }

      .post-top-right {
        svg {
          font-size: 22px;
        }
      }
    }
  }
}

.btn_box {
  position: absolute;
  bottom: 26px;
  //height: 200px;
  width: 100%;
  //background: #151515;
  display: flex;
  align-items: center;
  justify-content: center;

  .confirm {
    width: 320px;
    height: 44px;
    color: white;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff5c84;
    border-radius: 22px;
    box-shadow: 2px 1px 10px rgba(#ff5c84, 0.3);
  }
}
</style>
